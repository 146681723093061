document.addEventListener("DOMContentLoaded", () => {
  const supportsVideo = !!document.createElement("video").canPlayType;
  if (!supportsVideo) return;

  const video = document.querySelector("video");
  if (!video) return;

  const playPauseButton = document.querySelector(".video-button");
  const mobileImage = document.getElementById("mobile-image");
  const heading = document.getElementById("marquee-headline");
  const paragraph = document.getElementById("marquee-copy");
  const tagline = document.getElementById("marquee-tagline");
  const navItems = document.querySelectorAll(".video-nav li");

  // Define milestones and actions
  const milestones = [
    {
      time: 0,
      image: "/content/mobile_banner_Home_2025-1.jpg",
      heading: "RELIABILITY",
      tagline: "A Superior Foundation",
      paragraph:
        "Emphasizing reliability and adaptability, we enable sustainable growth and the exploration of new horizons, ensuring our organization's ongoing success.",
    },
    {
      time: 12,
      image: "/content/mobile_banner_Home_2025-2.jpg",
      heading: "DRIVING VALUE",
      tagline: "A Superior Focus",
      paragraph:
        "By empowering our portfolio of rental and service companies to achieve market leadership, profitability, safety, and operational excellence, we are positioning them for future growth and opportunities.",
    },
    {
      time: 24,
      image: "/content/mobile_banner_Home_2025-3.jpg",
      heading: "CORE VALUES",
      tagline: "A Superior Purpose",
      paragraph:
        "Superior's Shared Core Values fuel our success and shape our path forward, reflecting fidelity to our vision and purpose while differentiating us as a responsible employer, a dependable partner, and an engaged corporate citizen.",
    },
  ];

  // Utility to update content based on milestones
  const updateContent = (milestone, index) => {
    navItems.forEach((item) => item.classList.remove("highlight"));
    mobileImage.src = milestone.image;
    heading.textContent = milestone.heading;
    tagline.textContent = milestone.tagline;
    paragraph.textContent = milestone.paragraph;
    navItems[index].classList.add("highlight");
  };

  // Initialize milestones
  const initializeMilestones = () => {
    navItems.forEach((item, index) => {
      item.addEventListener("click", () => {
        video.currentTime = milestones[index].time;
        updateContent(milestones[index], index);
        video.play();
      });
    });
  };

  // Update progress borders
  const updateProgressBorders = (currentTime) => {
    milestones.forEach((milestone, index) => {
      const nextMilestone = milestones[index + 1];
      const startTime = milestone.time;
      const endTime = nextMilestone ? nextMilestone.time : video.duration;

      if (currentTime >= startTime && currentTime <= endTime) {
        const progress = ((currentTime - startTime) / (endTime - startTime)) * 100;
        navItems[index].style.setProperty("--progress-width", `${progress}%`);
      } else {
        navItems[index].style.setProperty("--progress-width", `0%`);
      }
    });
  };

  // Monitor video playback and trigger milestones
  const handleTimeUpdate = (() => {
    const triggeredMilestones = new Set();
    let previousTime = 0;

    return () => {
      const currentTime = Math.floor(video.currentTime);

      // Reset triggered milestones if video restarts
      if (currentTime < previousTime) triggeredMilestones.clear();
      previousTime = currentTime;

      // Trigger milestone actions
      milestones.forEach((milestone, index) => {
        if (!triggeredMilestones.has(milestone.time) && currentTime >= milestone.time) {
          updateContent(milestone, index);
          triggeredMilestones.add(milestone.time);
        }
      });

      // Update progress borders
      updateProgressBorders(currentTime);
    };
  })();

  // Play/pause button functionality
  const togglePlayPause = () => {
    playPauseButton.classList.toggle("playing");
    if (video.paused || video.ended) {
      video.play();
      playPauseButton.classList.add("playing");
    } else {
      video.pause();
      playPauseButton.classList.remove("playing");
    }
  };

  // Initialize functionality
  const initialize = () => {
    video.controls = false;
   // Ensure button state syncs with video state
    video.addEventListener("play", () => playPauseButton.classList.add("playing"));
    video.addEventListener("pause", () => playPauseButton.classList.remove("playing"));
    video.addEventListener("ended", () => playPauseButton.classList.remove("playing"));

    video.addEventListener("ended", () => playPauseButton.classList.remove("playing"));
    video.addEventListener("timeupdate", handleTimeUpdate);
    playPauseButton.addEventListener("click", togglePlayPause);
    initializeMilestones();
  };

  initialize();
});
